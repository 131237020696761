import React, { useContext, useEffect, useState } from "react";
import Widget from "./Widget";
import Loading from "../../components/common/Loading";
import randomColor from "randomcolor";
import { TicketApi } from "@unity/components";

export default function Dashboard(props) {

  const [active, setActive] = useState(true);
  const [data, setData] = useState(false);

  const getData = async () => {
    const res = await TicketApi.getTicketCounts();
    setData(res.data);
  }

  useEffect(() => {
    getData();
  }, []);

  if (active && data) {
    return (
      <div className="container-fluid">
        <div
          className="row"
        >
          {data.map((item, key) => {
            let color = randomColor();
            return (
              <div className="col-lg-3" style={{ marginBottom: 25 }} key={key}>
                <Widget
                  count={item.count}
                  title={item.name}
                  bg={color}
                />
              </div>
            )
          })}

        </div>
      </div>
    );
  } else {
    return <Loading />;
  }
}
